import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { useGetCollectionYardVisitsQuery } from "../../api/citizenApi";
import { ContentLoader } from "../../components/ContentLoader";
import { GeneralTitle } from "../../components/GeneralTitle";
import { Layout } from "../../components/Layout/Layout";
import { Navbar } from "../../components/Navbar";
import { NavigateBackWithContent } from "../../components/NavigateBackWithContent";
import { getSelectedYear } from "../../selectors";
import { RECYCLING_PATH } from "../../constants";
import { CollectionYardVisits } from "../../components/PagesContent/CollectionYardVisits";

export const CollectionYardDetailPage = () => {
  const { t } = useTranslation();

  const { collectionYardId } = useParams();
  const selectedYear = useSelector(getSelectedYear);
  const { data, isFetching, error } = useGetCollectionYardVisitsQuery({
    year: selectedYear,
    id: collectionYardId!,
  });

  if (error) {
    return <Navigate to={RECYCLING_PATH} />;
  }

  if (isFetching || data === undefined) return <ContentLoader />;

  const topBar = (
    <NavigateBackWithContent
      rightComponent={<GeneralTitle mainText={t("collectionYard")} secondaryText={selectedYear} />}
    />
  );
  const navBar = <Navbar />;

  return (
    <Layout topBar={topBar} navBar={navBar}>
      <CollectionYardVisits visits={data} />
    </Layout>
  );
};
