import React from "react";
import { useSelector } from "react-redux";
import {
  useGetCollectionYardsVisitsSummaryQuery,
  useGetWasteDetailOfYearQuery,
} from "../../../api/citizenApi";
import { getSelectedYear } from "../../../selectors";
import { getWasteTypeName, wasteTypeToColorMapping } from "../../../utils";
import { Box } from "../../Box";

import { CollectedWasteOverview } from "../../CollectedWasteOverview";
import { ContentLoader } from "../../ContentLoader";
import { MyContainersInfo } from "../../MyContainersInfo";
import { SummaryDoughnutChart } from "../../SummaryDoughnutChart";
import { CollectionYardsInfo } from "../../CollectionYardsInfo";

const BASIC_WASTE_TYPES = ["mixed", "paper", "plastics", "glass", "bio", "vkm", "metal"];

export const Recycling = () => {
  const selectedYear = useSelector(getSelectedYear);
  const { data, isFetching } = useGetWasteDetailOfYearQuery(selectedYear);
  const collectionYards = useGetCollectionYardsVisitsSummaryQuery(selectedYear);

  // if (error) {
  //   if ("data" in error && error.data.message.length > 0) throw new Error(error.data.message[0]);
  //   else throw new Error("Something went wrong");
  // }

  if (isFetching || data === undefined) return <ContentLoader />;

  // Filter out not wanted waste type data
  const detailData = data.detail
    .filter(
      (
        item // Here item.type is actually wasteType
      ) => BASIC_WASTE_TYPES.includes(item.type.toString())
      // Sorting according to BASIC_WASTE_TYPES order
    )
    .sort(
      (a, b) =>
        BASIC_WASTE_TYPES.indexOf(a.type.toString()) - BASIC_WASTE_TYPES.indexOf(b.type.toString())
    );

  const detailDataWithColors = detailData.map(entry => {
    let color = wasteTypeToColorMapping.get(entry.type);
    if (color === undefined) {
      color = "white";
    }
    return {
      ...entry,
      color,
    };
  });

  const filteredContainersWithColors = data.containers
    .filter(
      container =>
        container.RFID && BASIC_WASTE_TYPES.includes(getWasteTypeName(container.wasteType))
    )
    .map(container => {
      let color = wasteTypeToColorMapping.get(getWasteTypeName(container.wasteType));
      if (color === undefined) {
        color = "white";
      }
      return {
        ...container,
        color,
      };
      // Same sorting tactic as above
    })
    .sort(
      (a, b) =>
        BASIC_WASTE_TYPES.indexOf(getWasteTypeName(a.wasteType)) -
        BASIC_WASTE_TYPES.indexOf(getWasteTypeName(b.wasteType))
    );

  return (
    <React.Fragment>
      <Box>
        <SummaryDoughnutChart data={detailDataWithColors} />
        <CollectedWasteOverview data={detailDataWithColors} />
      </Box>
      <MyContainersInfo containers={filteredContainersWithColors} />
      <CollectionYardsInfo collectionYards={collectionYards.data ?? []} />
    </React.Fragment>
  );
};
