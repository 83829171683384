import React from "react";
import styles from "./CollectionYardVisitItem.module.scss";
import { formattedDateTime } from "../../../utils";
import { useTranslation } from "react-i18next";
import { CollectionYardVisit } from "../../../types/collectionYards";
import { formatCurrency } from "../../../utils/currency";
import { formatWeight } from "../../../utils/weight";

type CollectionYardVisitProps = {
  visit: CollectionYardVisit;
};

export const CollectionYardVisitItem: React.FC<CollectionYardVisitProps> = ({ visit }) => {
  const { visitId, wasteSum, created, priceSum, wasteTypes } = visit;
  const { t } = useTranslation();

  return (
    <div className={styles.item} key={visitId}>
      <h6 className={styles.itemTitle}>
        <span>{formatWeight(wasteSum)}</span>
        <div className={styles.divider} />
        <span>{formatCurrency(priceSum)}</span>
      </h6>
      <div className={styles.wasteTypes}>
        {wasteTypes.map(wasteType => t(`enums.allWasteTypes.${wasteType}`)).join(", ")}
      </div>
      <div>{formattedDateTime(new Date(created))}</div>
    </div>
  );
};
