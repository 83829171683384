import React from "react";
import { BoxWithDividerLines } from "../../BoxWithDividerLines";
import { useTranslation } from "react-i18next";
import { CollectionYardVisit } from "../../../types/collectionYards";
import { CollectionYardVisitItem } from "../CollectionYardVisitItem";

type CollectionYardVisitsProps = {
  visits: CollectionYardVisit[];
};

export const CollectionYardVisits: React.FC<CollectionYardVisitsProps> = ({ visits }) => {
  const { t } = useTranslation();

  const collectionBoxItems = visits.map(visit => ({
    id: visit.visitId,
    element: <CollectionYardVisitItem visit={visit} />,
  }));

  if (visits.length === 0) {
    return <div>{t("noData")}</div>;
  }

  return <BoxWithDividerLines style={{ padding: "0.55rem" }} items={collectionBoxItems} />;
};
