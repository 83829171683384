import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
  Plugin,
  ChartOptions,
  Chart,
  ChartEvent,
  ActiveElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import ChartDataLabels from "chartjs-plugin-datalabels";

import styles from "./SummaryLineChart.module.scss";
import { capitalizeString, monthsArray } from "../../utils";

import { LineChartDataItem } from "../../types";
import { AnyObject } from "chart.js/types/basic";
import { NoChartData } from "../NoChartData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface SummaryLineChartProps {
  data: LineChartDataItem[];
  municipalityDegreeOfSeparation: number;
}

const labelColor = "#B0BBCB";
const dashedLineColor = "#DDE2E7";
const degreesOfSeparationColor = "#56C329";
const labelBodyColor = "#B0BBCB";

const tickColors = [labelColor, labelColor, labelColor, labelColor, labelColor, labelColor];

export const SummaryLineChart: React.FC<SummaryLineChartProps> = ({
  data,
  municipalityDegreeOfSeparation,
}) => {
  const { t } = useTranslation();

  if (data.length === 0) {
    return <NoChartData />;
  }

  const fullLabels = data.map(item => {
    if (item.month === 0) {
      return "";
    }
    const month = monthsArray[item.month - 1];
    const translation = t(`enums.months.${month}`);
    const capitalizedTranslation = capitalizeString(translation);
    return capitalizedTranslation;
  });

  const labels = fullLabels.map(fullLabel => fullLabel.slice(0, 3));

  const values = data.map(({ value }) => value);
  const maxValue = values.reduce((a, b) => Math.max(a, b), -Infinity);

  const maxGraphValue =
    municipalityDegreeOfSeparation <= maxValue ? 0 : municipalityDegreeOfSeparation;
  const step = Math.max(maxValue, maxGraphValue) > 50 ? 20 : 10;

  const chartData = () => {
    return {
      labels,
      datasets: [
        {
          label: t("degreeOfSeparation"),
          data: values,
          borderColor: degreesOfSeparationColor,
          fill: true,
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const height = context.chart.height;
            const gradientBg = ctx.createLinearGradient(0, 0, 0, height);
            gradientBg.addColorStop(0.2, "rgba(223,243,214,255)");
            gradientBg.addColorStop(0.8, "rgba(255,255,255,1)");
            return gradientBg;
          },
          pointBackgroundColor: degreesOfSeparationColor,
          pointBorderColor: "white",
          pointBorderWidth: 4,
          pointRadius: 6.5,
        },
      ],
    };
  };

  const options: ChartOptions<"line"> = {
    elements: {
      point: {
        radius: 20,
        pointStyle: "circle",
      },
      line: {
        tension: 0.4,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        suggestedMax: maxGraphValue,
        ticks: {
          stepSize: step,
          color: labelColor,
          font: {
            size: 13,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        offset: true,
      },
      x: {
        ticks: {
          color: tickColors,
          font: {
            size: 13,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        offset: true,
      },
    },
    onClick(_: ChartEvent, points: ActiveElement[], chart: Chart) {
      if (points[0] && chart.config?.options?.scales?.x?.ticks) {
        const datapoint = points[0].index;

        const newColors = [...tickColors];
        newColors[datapoint] = degreesOfSeparationColor;
        chart.config.options.scales.x.ticks.color = newColors;
        chart.update();
      } else if (chart.config?.options?.scales?.x?.ticks) {
        chart.config.options.scales.x.ticks.color = tickColors;
        chart.update();
      }
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#FFFFFF",
        titleColor: degreesOfSeparationColor,

        padding: 5,
        bodyColor: "rgba(0,0,0,0)",
        xAlign: "center",
        yAlign: "bottom",

        borderColor: labelBodyColor,
        borderWidth: 0.8,
        cornerRadius: 3,
        displayColors: false,
        callbacks: {
          title(item: any) {
            const value = item[0].raw;
            const title = `${value} %`;
            return title;
          },
          label(_: any) {
            return "";
          },
        },
      },
    },
  };

  const plugins: Plugin<"line", AnyObject>[] = [
    {
      id: "lineChart",
      afterDatasetDraw: (chart: any): void => {
        const { ctx, scales } = chart;

        ctx.save();
        ctx.strokeStyle = dashedLineColor;
        ctx.setLineDash([5, 5]);
        if (chart.getDatasetMeta(0).data.length > 0) {
          ctx.strokeRect(
            chart.getDatasetMeta(0).data[0].x,
            scales.y.getPixelForValue(municipalityDegreeOfSeparation),
            chart.getDatasetMeta(0).data[data.length - 1].x - chart.getDatasetMeta(0).data[0].x,
            0
          );
        }

        ctx.restore();
      },
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.lineChartContainer}>
        <Line className={styles.lineChart} data={chartData()} options={options} plugins={plugins} />
      </div>
    </div>
  );
};
