import { IconType } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { Navbar } from "../../components/Navbar";
import { NavigationTitle } from "../../components/NavigationTitle";
import { MyEwaCard } from "../../components/PagesContent/MyEwaCard";
import { Icons } from "../../utils";

export const MyEwaCardPage = () => {
  const topBar = (
    <NavigationTitle title="cardEwa" iconName={Icons.arrowLeft} iconType={IconType.default} />
  );
  const navBar = <Navbar />;

  return (
    <Layout topBar={topBar} navBar={navBar} includeGaps={false} fixedTop={false}>
      <MyEwaCard />
    </Layout>
  );
};
