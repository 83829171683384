export const INDEX_PATH = "/";
export const AUTH_PATH = "/auth";
export const LOGIN_PATH = "/auth/login";
export const ASK_FOR_PASSWORD_RESET_PATH = "/auth/ask-for-password-reset";
export const HOW_TO_REGISTER_PATH = "/auth/how-to-register";
export const RESET_PASSWORD_PATH = "/auth/reset-password";
export const REGISTER_PATH = "/auth/register";
export const HOME_PATH = "/home";
export const RECYCLING_PATH = "/recycling";
export const CALENDAR_PATH = "/calendar";
export const PROFILE_PATH = "/profile";
export const WEIGHT_HISTORY_PATH = "/recycling/weight-history";
export const COLLECTION_YARD_PATH = "/recycling/collection-yard";
export const CHANGE_PASSWORD_PATH = "/profile/change-password";
export const NOTIFICATION_SETTINGS_PATH = "/profile/notification-settings";
export const CHANGE_LANGUAGE_PATH = "/profile/change-language";
export const CHANGE_EMAIL_PATH = "/profile/change-email";
export const MY_EWA_CARD_PATH = "/profile/my-ewa-card";
