import { format } from "date-fns";
import i18next from "i18next";

export const formattedDate = (date: Date): string => {
  return format(date, "dd. M. yyyy");
};
export const formattedDateTime = (date: Date): string => {
  return format(date, "dd. M. yyyy HH:mm");
};
export const transformDayShortcut = (day: string): string => {
  if (day === "pi") return "pia";
  return day;
};
export const getEndOfYearDate = (year: number) => {
  return new Date(year, 11, 31, 23, 59, 59);
};
export const getLocalizedShortWeekDay = (date: Date): string => {
  return new Intl.DateTimeFormat(i18next.language, { weekday: "short" }).format(date);
};
export const getDayOfMonth = (date: Date): number => {
  return date.getDate();
};

export const getNDaysAheadDate = (aheadDays: number) => {
  const now = new Date();
  const futureDate = new Date();
  futureDate.setFullYear(now.getFullYear());
  futureDate.setDate(now.getDate() + aheadDays);
  futureDate.setHours(23);
  futureDate.setMinutes(59);
  futureDate.setSeconds(0, 0);
  return futureDate;
};
