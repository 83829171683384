import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  CHANGE_LANGUAGE_PATH,
  CHANGE_PASSWORD_PATH,
  MY_EWA_CARD_PATH,
  NOTIFICATION_SETTINGS_PATH,
} from "../../constants";
import { Icons } from "../../utils";
import { BoxWithDividerLines } from "../BoxWithDividerLines";
import { IconType } from "../Icon";
import {
  RowWithIconContainer,
  RowContainerProps,
} from "../RowWithIconContainer/RowWithIconContainer";

export const profileControls: RowContainerProps[] = [
  {
    iconName: Icons.language,
    mainText: "changeLanguage",
    rightIconName: Icons.arrowRight,
    path: CHANGE_LANGUAGE_PATH,
  },
  {
    iconName: Icons.notification,
    mainText: "notificationSettings",
    rightIconName: Icons.arrowRight,
    path: NOTIFICATION_SETTINGS_PATH,
  },
  {
    iconName: Icons.lock,
    iconType: IconType.gray,
    mainText: "changePassword",
    rightIconName: Icons.arrowRight,
    path: CHANGE_PASSWORD_PATH,
  },
  {
    iconName: Icons.card,
    iconType: IconType.gray,
    mainText: "cardEwa",
    rightIconName: Icons.arrowRight,
    path: MY_EWA_CARD_PATH,
  },
  // {
  //   iconName: "mail",
  //   iconType: IconType.blue,
  //   mainText: CHANGE_EMAIL_PATH,
  //   rightIconName: "arrowRight",
  // },
];

export const ProfileControls = () => {
  const { t } = useTranslation();

  const controlBoxItems = profileControls.map(
    ({ iconName, iconType, mainText, rightIconName, path }, index) => ({
      id: index,
      element: (
        <Link to={path ?? ""}>
          <RowWithIconContainer
            iconName={iconName}
            iconType={iconType}
            mainText={t(mainText)}
            rightIconName={rightIconName}
          />
        </Link>
      ),
    })
  );

  return (
    <BoxWithDividerLines
      style={{ padding: "0.6em", paddingLeft: "1rem" }}
      items={controlBoxItems}
    />
  );
};
