import { useTranslation } from "react-i18next";
import { CollectionYardVisitsSummary } from "../../types/collectionYards";
import { Icon } from "../Icon";
import styles from "./RowWithIconCollectionYard.module.scss";
import { Icons } from "../../utils";
import { formatWeight } from "../../utils/weight";

export interface RowCollectionYardProps {
  collectionYard: CollectionYardVisitsSummary;
}

export const RowWithIconCollectionYard: React.FC<RowCollectionYardProps> = ({ collectionYard }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Icon style={{ width: "2.5rem", height: "auto" }} name="recycleGreen" />
      </div>
      <h3 className={styles["main-text"]}>{collectionYard.name}</h3>
      <h4 className={styles["secondary-text-container"]}>
        <p>
          {collectionYard.visitCount} {t("records")}
        </p>
        <div className={styles.divider} />
        <p>{formatWeight(collectionYard.wasteSum)}</p>
      </h4>
      <Icon
        style={{
          width: "1.5rem",
          height: "auto",
          gridArea: "1/3/3/3",
          justifySelf: "flex-end",
          alignSelf: "center",
        }}
        name={Icons.arrowRight}
      />
    </div>
  );
};
