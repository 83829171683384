import React from "react";
import { ContentWithTitle } from "../ContentWithTitle";
import { useTranslation } from "react-i18next";
import { BoxWithDividerLines } from "../BoxWithDividerLines";
import { Link } from "react-router-dom";
import { COLLECTION_YARD_PATH } from "../../constants";
import { CollectionYardVisitsSummary } from "../../types/collectionYards";
import { RowWithIconCollectionYard } from "../RowWithIconCollectionYard";
import { Box } from "../Box";

type CollectionYardsInfoProps = {
  collectionYards: CollectionYardVisitsSummary[];
};

export const CollectionYardsInfo: React.FC<CollectionYardsInfoProps> = ({ collectionYards }) => {
  const { t } = useTranslation();

  const containersBoxItems = collectionYards.map(collectionYard => {
    return {
      id: collectionYard.id,
      element: (
        <Link to={`${COLLECTION_YARD_PATH}/${collectionYard.id}`}>
          <RowWithIconCollectionYard collectionYard={collectionYard} />
        </Link>
      ),
    };
  });

  return (
    <ContentWithTitle title={t("myCollectionYards")}>
      {collectionYards.length > 0 && <BoxWithDividerLines items={containersBoxItems} />}
      {collectionYards.length === 0 && (
        <Box style={{ fontSize: "0.75rem" }}>{t("noCollectionYards")}</Box>
      )}
    </ContentWithTitle>
  );
};
